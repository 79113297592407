import React from 'react'
import { FieldValues } from 'react-hook-form'
import { Autosuggest } from '@/components/ui/ReactHookFormFields'
import { IAutosuggestProps } from '../Autosuggest/interfaces'
import {
  getSuggestionValue,
  onSuggestionSelectedWithAll,
  onSuggestionSelectedWithCode,
  onSuggestionSelectedWithName,
  onSuggestionSelectedWithNameAndRegionKladrId,
  onSuggestionsFetchRequestedFromAlfa,
  onSuggestionsFetchRequestedFromDadata,
} from './helpers'
import { messages } from './messages'
import { isCitySuggestionFormAlfa } from './typeguard'
import {
  IAlfaSuggestionCity,
  IDadataSuggestionCity,
  ISearchCitiesWithSuggestionsProps,
  TSearchingCitiesApiType,
  TSearchingCitiesFormatType,
} from './types'

const SuggestionCity: React.FC<IAlfaSuggestionCity | IDadataSuggestionCity> = cityInfo => {
  if (isCitySuggestionFormAlfa(cityInfo)) {
    const { region, name } = cityInfo

    return <div>{`${region?.name}, ${name}`}</div>
  }

  const { city } = cityInfo

  return <div>{`${city}`}</div>
}

const SuccessRequestStrategy: Record<
  TSearchingCitiesApiType,
  IAutosuggestProps<IAlfaSuggestionCity | IDadataSuggestionCity>['onSuggestionsFetchRequested']
> = {
  alfa: onSuggestionsFetchRequestedFromAlfa,
  dadata: onSuggestionsFetchRequestedFromDadata,
}

const SelectingSuggestionStrategy: Record<
  TSearchingCitiesFormatType,
  IAutosuggestProps<IAlfaSuggestionCity | IDadataSuggestionCity>['onSuggestionSelected']
> = {
  all: onSuggestionSelectedWithAll,
  name: onSuggestionSelectedWithName,
  code: onSuggestionSelectedWithCode,
  nameAndRegionKladrId: onSuggestionSelectedWithNameAndRegionKladrId,
}

export const SearchCitiesWithSuggestions = <TForm extends FieldValues>(props: ISearchCitiesWithSuggestionsProps<TForm>) => {
  const {
    name,
    isRequired = true,
    valueFormattingVariant = 'code',
    apiType = 'alfa',
    isRequiredWithoutSign,
    requiredError = messages.errors.required,
    onChange,
    className,
    control,
  } = props

  return (
    <Autosuggest<IAlfaSuggestionCity | IDadataSuggestionCity, TForm>
      control={control}
      name={name}
      suggestionComponent={SuggestionCity}
      isRequired={isRequired}
      errorCustomMessage={requiredError}
      getSuggestionValue={getSuggestionValue}
      onInputChange={onChange}
      onSuggestionsFetchRequested={SuccessRequestStrategy[apiType]}
      onSuggestionSelected={SelectingSuggestionStrategy[valueFormattingVariant]}
      customInputProps={{
        label: messages.placeholder,
        className: 'space-holder8',
      }}
      isRequiredWithoutSign={isRequiredWithoutSign}
      shouldHighlightFirstSuggestion
      className={className}
    />
  )
}
